import ForumLogoText from '@public/images/ForumLogoText.svg'
import Logo from '@public/images/ForummLogo.svg'
import { Button } from '@components/inputs/Button'
import { Form } from '@components/inputs/Form'
import { TextInput } from '@components/inputs/TextInput'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@libs/useAuth'
import Link from 'next/link'
import Box from '@components/base/Box'
import { useRouter } from 'next/router'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { useTheme } from '@libs/useTheme'

const LoginForm = () => {
  const { theme, setTheme } = useTheme()
  const { signInWithEmail, currentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (currentUser) {
      location.href = '/'
    }
  })

  const handlePasswordChange = (value: string, isInvalid?: boolean) => {
    setPassword(value)
  }

  return (
    <Box className="flex w-full justify-center h-screen items-center">
      <Box className="flex w-full justify-center items-center max-w-7xl flex-grow overflow-visible">
        <Box className="w-4/5 sm:w-1/2 my-16 relative">
          <Box className="flex flex-col items-center space-y-2">
            <Box
              onClick={() => (window.location.href = 'https://www.forumm.to')}
              className="text-center text-2xl cursor-pointer cursor-pointer"
            >
              <div className="flex justify-center items-center mb-12">
                <Logo className="block w-12 mr-2" />
                <ForumLogoText fill={theme.textColour} className="h-6 w-24" />
              </div>
              <p>Log in to continue</p>
            </Box>
          </Box>
          <Box>
            <Form
              onSubmit={async (data) => {
                setLoading(true)
                try {
                  const { email, password } = data
                  const response = await signInWithEmail(email, password)
                  if (
                    router.query.previous &&
                    !document.referrer.includes('www.forumm.to') &&
                    !document.referrer.includes('//forumm.to')
                  )
                    router.back()
                  else router.push('/')
                } catch (error: any) {
                  console.error(error)
                  setLoading(false)
                  return error.message ?? 'Failed to sign in'
                }
              }}
            >
              <TextInput
                label="Email"
                type="email"
                placeholder="your@mail.com"
                required
                validations={{
                  minLength: {
                    value: 7,
                    message: 'Email must be at least 7 characters',
                  },
                  maxLength: {
                    value: 70,
                    message: 'Email must be less than 70 characters',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email must be valid',
                  },
                }}
              />
              <div className="relative">
                {showPassword ? (
                  <TextInput
                    type="text"
                    label="Password"
                    placeholder="Enter your password"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    validations={{
                      minLength: {
                        value: 8,
                        message: 'Password is too short',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Password is too long',
                      },
                    }}
                  />
                ) : (
                  <TextInput
                    type="password"
                    label="Password"
                    placeholder="*******"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    validations={{
                      minLength: {
                        value: 8,
                        message: 'Password is too short',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Password is too long',
                      },
                    }}
                  />
                )}
                <button
                  type="button"
                  className="absolute right-0 top-[50%] -translate-y-[50%] text-xl items-center pr-3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </button>
              </div>
              <Button
                buttonType="submit"
                className="my-8 w-full"
                title="Log in"
                loading={loading}
                backgroundColor="#3763e9"
                textColor="white"
              />
            </Form>
          </Box>
          <Box className="text-sm flex space-x-2 justify-center mb-2 mt-2">
            <span className="">{"Don't have an Account?"}</span>
            <Link className="text-link hover:underline" href="/create-account">
              Sign up
            </Link>
          </Box>
          <Box className="text-sm flex space-x-2 justify-center mb-8">
            <span className="">Forgotten Password?</span>
            <Link className="text-link hover:underline" href="/forgot-password">
              Reset
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LoginForm
